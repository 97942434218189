import { functions } from "./firebase";
import jwt from "jsonwebtoken";

var lat = ``;
var long = ``;
export function getOfferDetails(parsed) {
	const offerInfo = functions.httpsCallable("offerInfo");
	if (typeof parsed.p === "string") parsed.p = new Array(parsed.p);
	if (typeof parsed.v === "string") parsed.v = new Array(parsed.v);
	return new Promise(function(resolve, reject) {
		offerInfo(
			Object.hasOwnProperty.bind(parsed)("c")
				? {
						store: parsed.s,
						id: parsed.p,
						currency: parsed.c
				  }
				: {
						store: parsed.s,
						id: parsed.p
				  }
		)
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}

export function updatePayment(token) {
	const createStripeCustomer = functions.httpsCallable(
		"createStripeCustomer"
	);
	return new Promise(function(resolve, reject) {
		createStripeCustomer({
			token: token
		})
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}

export function submitOffer(offer) {
	const submitOffer = functions.httpsCallable("submitOffer");
	return new Promise(function(resolve, reject) {
		submitOffer(offer)
			.then(res => {
				console.log(res);
				if (res.data.error) {
					console.error(res.data.error);
					reject(res.data.error);
				} else {
					resolve(res);
				}
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}

export function isOfferable(products, store) {
	if (typeof products === "string") products = new Array(products);
	const isOfferable = functions.httpsCallable("isOfferable");
	return new Promise(function(resolve, reject) {
		isOfferable({ products, store })
			.then(res => {
				console.log(res);
				resolve(res.data);
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}

export function getDeliveryDetails(parsed) {
	const deliveryInfo = functions.httpsCallable("deliveryInfo");
	if (typeof parsed.p === "string") parsed.p = new Array(parsed.p);
	if (typeof parsed.v === "string") parsed.v = new Array(parsed.v);
	return new Promise(function(resolve, reject) {
		deliveryInfo({
			store: parsed.s,
			id: parsed.p
		})
			.then(res => {
				resolve(res);
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}
function getLocation() {
	if (navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(showPosition);
	}
}

function showPosition(position) {
	lat = position.coords.latitude;
	long = position.coords.longitude;
}

function get_browser() {
	var ua = navigator.userAgent,
		tem,
		M =
			ua.match(
				/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
			) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return { name: "IE", version: tem[1] || "" };
	}
	if (M[1] === "Chrome") {
		tem = ua.match(/\bOPR|Edge\/(\d+)/);
		if (tem != null) {
			return { name: "Opera", version: tem[1] };
		}
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
	if ((tem = ua.match(/version\/(\d+)/i)) != null) {
		M.splice(1, 1, tem[1]);
	}
	return {
		name: M[0],
		version: M[1]
	};
}

export function errorEmail(message, email) {
	const errorEmail = functions.httpsCallable("errorEmail");
	var newDate = new Date();
	var dateString = newDate.toString();
	getLocation();
	var browser = get_browser();
	return new Promise(function(resolve, reject) {
		errorEmail({
			message: message,
			email: email,
			url: window.location.href,
			date: dateString,
			lat: lat,
			long: long,
			browser: `${browser.name} ${browser.version}`
		})
			.then(res => {
				console.log(res);
				resolve(true);
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}

export async function applyCoupon(offer) {
	const putCoupon = functions.httpsCallable("applyCoupon");
	return putCoupon(offer);
}

export function convertAnonymousUser() {
	const convertAnon = functions.httpsCallable("convertAnonymousUser");
	return new Promise(function(resolve, reject) {
		convertAnon()
			.then(res => {
				console.log(res);
				resolve(true);
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}

export function getPaymentDetails() {
	const paymentInfo = functions.httpsCallable("paymentInfo");
	return new Promise(function(resolve, reject) {
		paymentInfo()
			.then(res => {
				console.log(res);
				resolve(jwt.decode(res.data).data.payment);
			})
			.catch(e => {
				console.error(e);
				reject(e);
			});
	});
}

export const getThemeColor = async store => {
	const themeColor = functions.httpsCallable("themeColor");
	var color = await themeColor({ store: store });
	return color.data === undefined || color.data === null
		? "#35B2FF"
		: color.data;
};
