import { db, auth, app } from "./firebase";

export function updateAddress(address, type) {
	var uid = auth.currentUser.uid;
	db.ref(`users/${uid}/meta/${type}`)
		.set(address)
		.then(() => {
			// window.location.reload()
		});
}

export function updateEmail(email) {
	var uid = auth.currentUser.uid;
	db.ref(`users/${uid}/meta/email`)
		.set(email)
		.then(() => {
			// window.location.reload()
		});
}

export function upadateFirstName(firstName) {
	var uid = auth.currentUser.uid;
	db.ref(`users/${uid}/meta/firstName`)
		.set(firstName)
		.then(() => {
			// window.location.reload()
		});
}

export function upadateLastName(lastName) {
	var uid = auth.currentUser.uid;
	db.ref(`users/${uid}/meta/lastName`)
		.set(lastName)
		.then(() => {
			// window.location.reload()
		});
}

export function updateCurrency(currency) {
	if (
		currency.currency === undefined ||
		currency.currency_symbol === undefined
	) {
		currency = { currency: "AUD", currency_symbol: "$" };
	}
	var uid = auth.currentUser.uid;
	db.ref(`users/${uid}/meta/payment`)
		.update(currency)
		.then(() => {
			// window.location.reload()
		});
}

export function updateMetaItem(what, object) {
	var uid = auth.currentUser.uid;
	db.ref(`users/${uid}/meta/${what}`)
		.set(object)
		.then(() => {
			// window.location.reload()
		});
}

export function getAddress(type) {
	return new Promise(function(resolve, reject) {
		var uid = auth.currentUser.uid;
		db.ref(`/users/${uid}/meta/${type}`)
			.once("value", snapshot => {
				return resolve(snapshot.val());
			})
			.catch(e => {
				return reject(e);
			});
	});
}

export function getPayment() {
	return new Promise(function(resolve, reject) {
		var uid = auth.currentUser.uid;
		db.ref(`/users/${uid}/meta/payment`)
			.once("value", snapshot => {
				return resolve(snapshot.val());
			})
			.catch(e => {
				return reject(e);
			});
	});
}

export function getName() {
	return new Promise(function(resolve, reject) {
		var uid = auth.currentUser.uid;
		db.ref(`/users/${uid}/meta/`)
			.once("value", snapshot => {
				return resolve(snapshot.val());
			})
			.catch(e => {
				return reject(e);
			});
	});
}

export function getMeta() {
	return new Promise(function(resolve, reject) {
		var uid = auth.currentUser.uid;
		db.ref(`/users/${uid}/meta`)
			.once("value", snapshot => {
				return resolve(snapshot.val());
			})
			.catch(e => {
				return reject(e);
			});
	});
}

export function getMetaItem(what) {
	return new Promise(function(resolve, reject) {
		var uid = auth.currentUser.uid;
		db.ref(`/users/${uid}/meta/${what}`)
			.once("value", snapshot => {
				return resolve(snapshot.val());
			})
			.catch(e => {
				return reject(e);
			});
	});
}

export function getAnon() {
	return new Promise(function(resolve, reject) {
		auth.onAuthStateChanged(function(user) {
			if (user) {
				db.ref(`/users/${user.uid}/meta/anonymous`)
					.once("value", snapshot => {
						return resolve(snapshot.val());
					})
					.catch(e => {
						return reject(e);
					});
			} else {
				// No user is signed in.
			}
		});
	});
}

export async function updateAnon(status) {
	var uid = auth.currentUser.uid;
	return await db
		.ref(`users/${uid}/meta/anonymous`)
		.set(status)
		.then(() => {
			// window.location.reload()
		});
}

export function updateOfferUrl() {
	var uid = auth.currentUser.uid;
	db.ref(`users/${uid}/meta/`)
		.update({ recentOfferUrl: window.location.href })
		.then(() => {
			// window.location.reload()
		});
}

export function isOnline() {
	var ref = db.ref(`/users/${auth.currentUser.uid}/meta/isOnline`);
	return ref.once("value", snapshot => {
		ref.set(true);
		ref.onDisconnect().set(app.database.ServerValue.TIMESTAMP);
	});
}
