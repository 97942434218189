import {
	auth
} from './firebase';

// Sign Up
export const doCreateUserWithEmailAndPassword = (email, password) =>
	auth.createUserWithEmailAndPassword(email, password);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
	auth.signInWithEmailAndPassword(email, password);

// Sign out
export const doSignOut = () =>
	auth.signOut();
// Password Reset
export const doPasswordReset = (email) =>
	auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = (password) =>
	auth.currentUser.updatePassword(password);

// Anonymous sign up
// Need to change the user meta anonymous into false
export const anonymousSignUp = (email, password) =>{
	var credential = auth.EmailAuthProvider.credential(email, password);
	auth.currentUser.linkAndRetrieveDataWithCredential(credential).then(function(usercred) {
		var user = usercred.user;
		console.log("Anonymous account successfully upgraded", user);
	  }, function(error) {
		console.log("Error upgrading anonymous account", error);
	});
}
