import React, { useState, useEffect, Fragment } from "react";
import { db } from "../firebase";
import PropTypes from "prop-types";
import { withStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, CssBaseline, Button, TextField } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import InputAdornment from "@material-ui/core/InputAdornment";
import QueryString from "query-string";
import validator from "validator";

const styles = theme => ({
	appBar: {
		top: "auto",
		bottom: 0,
		backgroundColor: theme.palette.secondary.main,
		padding: "0.5em",
		textAlign: "center",
		zIndex: 999
	},
	cartTotal: {
		display: "block",
		fontWeight: "700",
		width: "100%",
		borderRadius: "2px",
		fontSize: "1.2em",
		textAlign: "center"
		// color: "#000"
	},
	button: {
		color:
			theme.palette.primary.main === "#35B2FF" ||
			theme.palette.primary.main === "#35b2ff"
				? "#FFF"
				: theme.palette.primary.contrastText,
		background: theme.palette.primary.main,
		border: "none",
		width: "100%",
		fontSize: "1.3em",
		fontWeight: "600",
		letterSpacing: "1px",
		display: "inline-block",
		margin: "0em auto 0.5em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark
		}
	},
	bottomInfo: {
		maxWidth: 500,
		margin: "auto"
	},
	inputLoc: {
		width: "100%",
		height: "2.5em",
		margin: "0.3em 0em"
	}
});

function BottomAppBar(props) {
	const { classes } = props;
	const theme = useTheme();
	const [values, setValues] = useState({
		email: "",
		emailEntered: false,
		error: false,
		anonymous: true,
		cartTotal: props.sendCartTotal
	});

	const handleNext = () => {
		if (props.tabType < 2) {
			props.parentCallbackNext(props.tabType);
		}
	};
	useEffect(() => {
		const parsed = QueryString.parse(window.location.search);
		let flag = true;
		if (parsed.e !== undefined) {
			setValues({
				...values,
				emailEntered: true,
				email: parsed.e,
				anonymous: false
			});
			db.getMeta()
				.then(snapshot => {
					const newValues = { ...values };
					if (
						snapshot.anonymous === false ||
						snapshot.anonymous === undefined ||
						snapshot.anonymous === null
					) {
						newValues.anonymous = false;
						newValues.email = snapshot.email;
						newValues.emailEntered = true;
					} else if (
						snapshot.email !== "" &&
						snapshot.email !== undefined &&
						snapshot.email !== null
					) {
						newValues.email = snapshot.email;
						newValues.emailEntered = true;
						newValues.anonymous = true;
					} else {
						newValues.anonymous = true;
					}
					setValues(newValues);
				})
				.catch(e => {
					console.log(e);
				});
			flag = false;
		}
		if (flag) {
			db.getMeta()
				.then(snapshot => {
					const newValues = { ...values };
					if (
						snapshot.anonymous === false ||
						snapshot.anonymous === undefined ||
						snapshot.anonymous === null
					) {
						newValues.anonymous = false;
						if (flag) {
							newValues.email = snapshot.email;
							newValues.emailEntered = true;
						}
						// props.parentCallbackEmailEntered(true, snapshot.email);
					} else if (
						snapshot.email !== "" &&
						snapshot.email !== undefined &&
						snapshot.email !== null
					) {
						if (flag) {
							newValues.email = snapshot.email;
							newValues.emailEntered = true;
						}
						newValues.anonymous = true;
					} else {
						newValues.anonymous = true;
					}
					setValues(newValues);
				})
				.catch(e => {
					console.log(e);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmitEmail = () => {
		// var re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

		// if (/^.+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.email)) {
		if (validator.isEmail(values.email)) {
			props.parentCallbackEmailEntered(true, values.email);
			setValues({ ...values, emailEntered: true, error: false });
			handleNext();
		} else {
			props.parentCallbackEmailEntered(false);
			setValues({ ...values, emailEntered: false, error: true });
		}
	};

	const handleChange = name => event => {
		// if (event.key === 'Enter' || event.key === 'Return') {
		//     handleSubmitEmail();
		//     event.preventDefault();
		// }
		setValues({
			...values,
			[name]: event.target.value
		});
	};

	const changeAmounts = event => {
		if (event.key === "Enter" || event.key === "Return") {
			props.parentCallbackNext(0);
			event.preventDefault();
		}
		setValues({ ...values, cartTotal: event.target.value });
		// props.parentCallbackTotal(values.cartTotal);
		//might need this
		//this.props.parentCallbackValues(newArray, this.state.weights)
	};

	useEffect(() => {
		props.parentCallbackTotal(values.cartTotal);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.cartTotal]);

	return (
		<Fragment>
			<CssBaseline />
			<AppBar
				position='fixed'
				className={classes.appBar}
				style={{
					display: props.tabType === 2 ? "none" : "block"
				}}>
				<div className={classes.bottomInfo}>
					<div className={classes.cartTotal}>
						<p
							style={{
								margin: "0px"
							}}>
							{props.cartAbandoned && props.tabType === 1 ? (
								<span
									style={{
										color: theme.palette.secondary.dark,
										display: "inline",
										float: "left"
									}}>
									Offer Total
								</span>
							) : (
								<span
									style={{
										color: theme.palette.secondary.dark,
										display: "inline",
										float: "left"
									}}>
									Cart Total
								</span>
							)}
							<span
								style={{
									display: "inline",
									marginLeft: "0.5em",
									color: theme.palette.primary.main,
									float: "right"
								}}>
								{props.currency} {props.currencySymbol}
								{props.cartAbandoned && props.tabType === 0
									? Number(props.cartFullValue).toFixed(2)
									: Number(props.sendCartTotal).toFixed(2)}
							</span>
						</p>
						<br />
					</div>
					{values.emailEntered || !values.anonymous ? (
						<Fade>
							<div>
								{props.cartAbandoned ? (
									<TextField
										id={`outlined-adornment-amount-0`}
										variant='outlined'
										placeholder='Enter Offer...'
										type='number'
										style={{
											width: "100%",
											display:
												props.tabType === 0
													? "block"
													: "none"
										}}
										pattern='[0-9]*'
										inputMode='numeric'
										value={values.cartTotal}
										onChange={changeAmounts}
										onKeyPress={changeAmounts}
										min='0'
										InputProps={{
											min: "0",
											classes: { input: classes.input },
											className: classes.inputLoc,
											type: "number",
											pattern: "[0-9]*",
											inputMode: "numeric",
											step: "1",
											startAdornment: (
												<InputAdornment position='start'>
													{props.currencySymbol}
												</InputAdornment>
											)
										}}
										inputProps={{
											"data-hj-whitelist": true
										}}
									/>
								) : null}
								<Button
									variant='contained'
									className={classes.button}
									onClick={handleNext}>
									{props.tabType === 0
										? "Proceed to Delivery"
										: props.tabType === 1
										? "Proceed to Payment"
										: "Submit Offer"}
								</Button>
							</div>
						</Fade>
					) : (
						<div>
							<TextField
								margin='dense'
								variant='outlined'
								style={{ marginTop: "0em" }}
								id='email'
								error={values.error}
								label='Email*'
								fullWidth
								autoFocus
								inputProps={{ "data-hj-whitelist": true }}
								value={values.email}
								onChange={handleChange("email")}
								onKeyPress={event => {
									if (event.key === "Enter") {
										handleSubmitEmail();
									}
								}}
							/>
							<Button
								variant='contained'
								className={classes.button}
								onClick={handleSubmitEmail}>
								Enter Email to Continue
							</Button>
						</div>
					)}
				</div>
			</AppBar>
		</Fragment>
	);
}

BottomAppBar.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BottomAppBar);
