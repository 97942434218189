import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { app, db, cf, analytics } from "../firebase";
import QueryString from "query-string";
import { Link, Redirect } from "react-router-dom";
import withSizes from "react-sizes";
import Fade from "react-reveal/Fade";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Header, ErrorSnackbar, FacebookPixel } from "../components";
import Lottie from "react-lottie";
import * as offerSubmitted from "../assets/offerSubmitted.json";

const useStyles = makeStyles(theme => ({
	"@global": {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	submit: {
		cursor: "pointer",
		color: "#f6f6f8",
		fontWeight: 700,
		background: theme.palette.primary.main,
		border: "none",
		letterSpacing: "1px",
		margin: "2em auto 0em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark
		}
	},
	root: {
		"& label.Mui-focused": {
			color: theme.palette.primary.main
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: theme.palette.primary.main
		},
		"& .MuiInput-underline": {
			borderBottomColor: theme.palette.primary.main
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				borderColor: theme.palette.primary.main
			},
			"&:hover fieldset": {
				borderColor: theme.palette.primary.main
			},
			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.main
			}
		}
	}
}));

function AnonymousSignUp(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [values, setValues] = useState({
		email: "",
		password: "",
		errorType: 14,
		errorOpen: false,
		errorMessage: "",
		signUpSuccess: false,
		flag: false
	});

	const handleChange = name => event => {
		setValues({ ...values, [name]: event.target.value });
	};

	const signup = event => {
		setValues({ ...values, flag: true });
		event.preventDefault();
		var credential = app.auth.EmailAuthProvider.credential(
			values.email,
			values.password
		);
		app.auth()
			.currentUser.linkWithCredential(credential)
			.then(
				async usercred => {
					var user = usercred.user;
					console.log(
						"Anonymous account successfully upgraded",
						user
					);
					await db.updateMetaItem("email", values.email);
					await cf.convertAnonymousUser();
					const q = QueryString.parse(window.location.search);
					if (
						window.location.hostname !== "localhost" &&
						window.location.hostname !== "127.0.0.1"
					) {
						FacebookPixel.track("CompleteRegistration", {
							storeName: q.s
						});
						analytics.logEvent("sign_up", {
							storeName: q.s,
							method: "email"
						});
						analytics.setUserProperties({ anonymous: false });
					}
					setValues({ ...values, signUpSuccess: true, flag: false });
				},
				function(error) {
					console.log("Error upgrading anonymous account", error);
					setValues({
						...values,
						errorOpen: true,
						errorMessage: error.message,
						errorType: 14
					});
				}
			);
	};

	const callbackCloseError = param => {
		setValues({ ...values, errorOpen: false });
	};

	const darkModeCallback = e => {
		props.darkModeCallback(e);
	};

	// if (!values.signUpSuccess) {
	return (
		<Container component='main' maxWidth='xs'>
			<ErrorSnackbar
				errorType={values.errorType}
				stripeError={values.errorMessage}
				open={values.errorOpen}
				parentCallbackCloseError={callbackCloseError}
			/>
			<Header username={``} darkModeCallback={darkModeCallback} />
			{values.redirect ? (
				<Redirect
					to={{
						pathname: "/",
						search: window.location.search
					}}
				/>
			) : null}
			<CssBaseline />
			<Grid
				container
				justifyContent='center'
				alignItems='center'
				spacing={0}
				direction='column'
				style={{ display: "flex" }}>
				<Fade>
					{values.signUpSuccess ? (
						<div
							style={{
								width: "25em",
								border: "1px solid #f6f6f8",
								boxShadow:
									"0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
								borderRadius: "5px",
								position: "fixed",
								top: "10%",
								padding: "3.75em 3.125em 4.375em 3.125em"
							}}>
							<Lottie
								options={{
									loop: false,
									autoplay: true,
									animationData: offerSubmitted.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
							/>
							<h2
								style={{
									textAlign: "center",
									paddingLeft: "0.625em",
									paddingRight: "0.625em",
									fontSize: "1.5em",
									fontWeight: "700",
									color: "#3e3e3e",
									letterSpacing: "1"
								}}>
								Sign Up Successful!
							</h2>
							<div
								style={{
									textAlign: "center"
								}}>
								<Button
									style={{
										color: theme.palette.primary.main,
										fontWeight: "700"
									}}
									onClick={() => {
										setValues({
											...values,
											redirect: true
										});
									}}>
									Offer
								</Button>
								|
								<Button
									style={{
										color: theme.palette.primary.main,
										fontWeight: "700"
									}}
									onClick={() => {
										window.location.href =
											"https://my.enteroffer.com";
									}}>
									Dashboard
								</Button>
							</div>
						</div>
					) : (
						<div
							style={{
								width: "25em",
								border: "1px solid #f6f6f8",
								boxShadow:
									"0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
								borderRadius: "5px",
								position: "fixed",
								top: "10%",
								padding: "3.75em 3.125em 4.375em 3.125em"
							}}>
							{/* <a href='https://www.enteroffer.com/'>
                                <img
                                    src={enterOfferBlue}
                                    alt='enteroffer'
                                    style={{
                                        width: '150px',
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginBottom: '2.5em',
                                        marginTop: '-1.25em'
                                    }}
                                />
                            </a> */}
							<h2
								style={{
									textAlign: "center",
									paddingLeft: "0.625em",
									paddingRight: "0.625em",
									fontSize: "1.5em",
									fontWeight: "800",
									color: "#3e3e3e",
									textTransform: "uppercase",
									letterSpacing: "1"
								}}>
								SIGN UP
							</h2>
							<form className={classes.form} noValidate>
								<TextField
									margin='normal'
									className={classes.root}
									fullWidth
									id='email'
									onChange={handleChange("email")}
									label='Email'
									name='email'
									autoComplete='email'
									autoFocus
								/>
								<TextField
									margin='normal'
									className={classes.root}
									fullWidth
									onChange={handleChange("password")}
									name='password'
									label='Password'
									type='password'
									id='password'
								/>
								<Button
									type='submit'
									fullWidth
									variant='contained'
									color='primary'
									className={classes.submit}
									onClick={signup}>
									Sign Up
								</Button>
							</form>
							<p
								style={{
									marginTop: "2em",
									textAlign: "center",
									cursor: "pointer"
								}}>
								<Link
									variant='body2'
									to={{
										pathname: "/signin",
										search: props.location.search
									}}
									style={{
										textDecoration: "none",
										color: theme.palette.primary.main,
										fontSize: "1.2em"
									}}>
									Already have an account? Sign in!
								</Link>
							</p>
							{values.flag ? <LinearProgress /> : null}
						</div>
					)}
				</Fade>
			</Grid>
		</Container>
	);
	// } else {
	//     var parsed = QueryString.parse(window.location.search)
	//     if (window.location.search.indexOf("r=") !== -1) {
	//         if (parsed.r === "user") {
	//             window.location.href = "https://my.enteroffer.com"
	//         } else if (parsed.r === "offer") {
	//             return (<Redirect
	//                 to={{
	//                     pathname: '/',
	//                     search: this.props.location.search
	//                 }}
	//             />)
	//         }
	//     }
	// }
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(AnonymousSignUp);
