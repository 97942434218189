import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import withSizes from "react-sizes";
import QueryString from "query-string";
import { db } from "../firebase";
import _ from "lodash";

import { Header } from "../components";

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 500,
		margin: "1em auto"
	},
	options: {
		padding: "1em 0em",
		fontWeight: 700,
		color: theme.palette.secondary.dark
	},
	optionsAns: {
		color: "#7f7f7f"
	}
}));

function Help(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [values, setValues] = useState({
		firstName: "",
		lastName: ""
	});

	var p = QueryString.parse(window.location.search);
	var retailerName = _.startCase(p.s);

	useEffect(() => {
		db.getName().then(snapshot => {
			setValues({
				firstName: snapshot.firstName,
				lastName: snapshot.lastName
			});
		});
	}, []);

	const darkModeCallback = e => {
		props.darkModeCallback(e);
	};

	return (
		(window.location.hash = "Help"),
		(
			<div style={{ fontFamily: props.font }}>
				<Header
					username={`${values.firstName} ${values.lastName}`}
					size={true}
					darkModeCallback={darkModeCallback}
				/>
				<div
					className={classes.root}
					style={{
						padding: props.isMobile ? "1em 2em" : null,
						marginTop: props.isMobile ? "4em" : "6em",
						fontFamily: props.font
					}}>
					<h2>
						<span
							style={{
								color: theme.palette.primary.main,
								fontWeight: 900
							}}>
							OFFER
						</span>
						<span
							style={{
								fontWeight: 900,
								color: theme.palette.secondary.dark
							}}>
							{" "}
							us your ideal price.
						</span>
					</h2>
					<Typography
						variant='body1'
						gutterBottom
						align='center'
						className={classes.options}>
						If{" "}
						<span
							style={{
								color: theme.palette.primary.main,
								fontWeight: "700"
							}}>
							{retailerName}
						</span>{" "}
						approves, you will receive notification via email with
						your payment and order processed.
					</Typography>
					<Divider />
					<Typography
						variant='body1'
						gutterBottom
						className={classes.options}>
						Why do I need to enter my payment details and shipping
						address?
					</Typography>
					<Typography
						variant='body1'
						gutterBottom
						className={classes.optionsAns}>
						You will not be charged for an offer until the retailer
						accepts your offer. When a retailer accepts your
						purchase is automatically processed. This saves you the
						effort of having to go through checkout again. Simply
						make an offer and if the retailer accepts, your product
						will be sent to you!
					</Typography>
					<br />
					<Divider />
					<Typography
						variant='body1'
						gutterBottom
						className={classes.options}>
						Can I make an offer without giving payment details?
					</Typography>
					<Typography
						variant='body1'
						gutterBottom
						className={classes.optionsAns}>
						No. All offers are agreements to purchase subject to the
						retailer accepting. We believe that all offers should be
						legitimate and that if a retailer is going to accept an
						offer, it should reflect a sale.
					</Typography>
					<br />
					<Divider />
					<Typography
						variant='body1'
						gutterBottom
						className={classes.options}>
						What is EnterOffer?
					</Typography>
					<Typography
						variant='body1'
						gutterBottom
						className={classes.optionsAns}>
						EnterOffer is a platform that provides online retailers
						the ability to let their customers make offers on their
						products
					</Typography>
					<br />
					<br />
					<Typography
						variant='body1'
						gutterBottom
						className={classes.optionsAns}>
						For more help, email <b>hello@enteroffer.com</b>
					</Typography>
				</div>
				<br />
				<br />
			</div>
		)
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(Help);
