import React, { useState } from "react";
import {
	Button,
	Typography,
	Dialog,
	DialogActions,
	DialogContent
} from "@material-ui/core";
import _ from "lodash";

export default function RoomDetails(props) {
	const [open, setOpen] = useState(false);
	const info = props.details;

	const handleClickOpen = e => {
		e.stopPropagation();
		setOpen(true);
	};

	const handleClose = e => {
		e.stopPropagation();
		setOpen(false);
	};
	var points = [];

	function Information(props) {
		return (
			<div>
				<h3>{props.heading}:</h3>
				{_.map(props.points, (point, key) => (
					<p key={key}>{point}</p>
				))}
			</div>
		);
	}

	return (
		<div>
			<Typography
				onClick={handleClickOpen}
				style={{
					fontSize: "0.8em",
					fontWeight: "700",
					color: "#E59C16",
					width: "100px",
					cursor: "pointer"
				}}>
				Room Details
			</Typography>
			<Dialog open={open} onClose={handleClose}>
				<DialogContent>
					{_.map(
						info,
						(string, key) => (
							(points = string.split(",")),
							(
								<Information
									points={points}
									heading={key}
									key={key}
								/>
							)
						)
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='primary' autoFocus>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
