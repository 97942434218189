import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";

export default function ErrorSnackbar(props) {
	const [state, setState] = useState({
		vertical: "top",
		horizontal: "center",
		Transition: Slide
	});

	const { vertical, horizontal } = state;

	const errorMessages = [
		{
			type: 0,
			message: "Oops! Something went wrong! :("
		},
		{
			type: 1,
			message: "Please choose an option."
		},
		{
			type: 2,
			message: "Please ensure all offers are greater than 1."
		},
		{
			type: 3,
			message:
				"Please ensure that all offers are less than the list price."
		},
		{
			type: 4,
			message: "Please ensure to enter your shipping address."
		},
		{
			type: 5,
			message: "Please ensure to enter your billing address."
		},
		{
			type: 6,
			message: "Please ensure to choose a shipping option."
		},
		{
			type: 7,
			message: "Please ensure to enter your payment details."
		},
		{
			type: 8,
			message: "Please ensure to add."
		},
		{
			type: 9,
			message:
				"The coupon you applied is invalid. Please try again or contact the retailer."
		},
		{
			type: 10,
			message: "Please update shipping since coupon is applied."
		},
		{
			type: 11,
			message: "Please ensure to enter all the required fields."
		},
		{
			type: 12,
			message: "Please ensure to select a shipping option."
		}
	];

	return (
		<div>
			<Snackbar
				anchorOrigin={{ vertical, horizontal }}
				key={`${vertical},${horizontal}`}
				open={props.open}
				autoHideDuration={5000}
				TransitionComponent={state.Transition}
				onClose={() => props.parentCallbackCloseError(false)}
				ContentProps={{
					"aria-describedby": "message-id"
				}}
				message={
					props.errorType === 8 ? (
						<span id='message-id'>
							Please ensure to add {props.message}.
						</span>
					) : props.errorType < 12 && props.errorType !== 8 ? (
						<span id='message-id'>
							{errorMessages[props.errorType].message}
						</span>
					) : props.errorType === 12 ? (
						<span id='message-id'>
							{errorMessages[props.errorType].message}
						</span>
					) : (
						<span id='message-id'>{props.stripeError}</span>
					)
				}
			/>
		</div>
	);
}
