import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import withSizes from "react-sizes";
import Lottie from "react-lottie";
import * as offerSubmitted from "../assets/offerSubmitted.json";
import _ from "lodash";
import Fade from "react-reveal/Fade";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import DoneIcon from "@material-ui/icons/Done";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import QueryString from "query-string";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { HelpModal } from "../components";

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1)
	},
	// submit: {
	//     cursor: 'pointer',
	//     color: '#f6f6f8',
	//     background: theme.palette.primary.main,
	//     border: 'none',
	//     letterSpacing: '1px',
	//     margin: '2em auto 0em auto',
	//     '&:hover': {
	//         backgroundColor: 'rgba(42, 107, 255, 1)'
	//     }
	// },

	image: {
		height: "130px"
	},
	img: {
		margin: "auto",
		display: "block",
		maxWidth: "100%",
		height: "100%",
		objectFit: "cover"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	},
	textField: {
		position: "relative",
		width: "100%"
	},
	textFieldDropdown: {
		// backgroundColor: '#f6f6f8',
		// borderRadius: '4px'
	},
	input: {
		padding: "0.3em",
		fontWeight: "700"
	},
	inputLoc: {
		position: "absolute",
		bottom: 0,
		width: "100%",
		height: "2.5em"
	},
	inputDrop: {
		marginLeft: " -4px",
		padding: "0.2em 0.8em 0.2em 0em",
		fontWeight: "700",
		fontSize: "0.8em"
	},
	notchedOutline: {
		borderColor: "transparent !important",
		paddingLeft: "0px !important"
	},
	adornment: {
		fontSize: "1.8em"
	},
	icon: {
		verticalAlign: "top",
		fontSize: "1.3em"
	},
	button: {
		color: "#656565",
		marginLeft: "1em"
	},

	bigButton: {
		color: "#fff",
		background: theme.palette.primary.main,
		textTransform: "uppercase",
		border: "none",
		width: "80%",
		fontSize: "1.3em",
		fontWeight: "700",
		letterSpacing: "1px",
		display: "inline-block",
		margin: "0em auto 0.5em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark
		}
	},
	toolbar: {
		alignItems: "center",
		justifyContent: "space-between"
	}
}));

function Submit(props) {
	const classes = useStyles();
	const theme = useTheme();

	const [data, setData] = useState(props.offer);
	const [openTerms, setOpenTerms] = useState(false);
	const [openPrivacy, setOpenPrivacy] = useState(false);

	// var data = {
	// 	total: 2,
	// 	anonymous: false,
	// 	shippingAddress: {
	// 		email: "snb.1996@gmail.com",
	// 		firstName: "Neil",
	// 		lastName: "Baksi",
	// 		company: "",
	// 		line1: "20 Poplar St",
	// 		line2: "",
	// 		city: "Sydney",
	// 		state: "NSW",
	// 		country: "Australia",
	// 		address_zip: "2000"
	// 	},
	// 	paymentInfo: {
	// 		currency: "AUD",
	// 		currency_symbol: "$"
	// 	},
	// 	offer: [
	// 		{
	// 			bidPrice: 1,
	// 			image:
	// 				"https://cdn.shopify.com/s/files/1/0091/4541/7785/products/beautiful-beauty-belt-1408183.jpg?v=1547157757",
	// 			price: 50,
	// 			productID: 123,
	// 			quantity: 1,
	// 			title: "Black Shirt",
	// 			variantID: 123243,
	// 			variantType: "S"
	// 		}
	// 	]
	// };

	const parsed = QueryString.parse(window.location.search);
	const handleClickOpenTerms = () => {
		setOpenTerms(true);
	};

	const handleClickOpenPrivacy = () => {
		setOpenPrivacy(true);
	};
	const handleCloseModal = () => {
		setOpenPrivacy(false);
		setOpenTerms(false);
	};
	return (
		(window.location.hash = "Submit"),
		(
			<div>
				<HelpModal
					openTerms={openTerms}
					openPrivacy={openPrivacy}
					handleClose={handleCloseModal}
				/>
				<Grid
					container
					// spacing={1}
					justify='center'
					style={{
						marginTop: "2em",
						width: "100%",
						fontFamily: props.font
					}}>
					<Fade>
						<Grid
							item
							xs={props.isMobile ? 12 : 6}
							style={{ margin: "auto" }}>
							<div style={{ textAlign: "center" }}>
								<img
									src={
										theme.palette.type === "dark"
											? "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlueAlt.png"
											: "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
									}
									alt='EnterOffer'
									style={{
										width: "8em",
										verticalAlign: "middle"
									}}
								/>
							</div>
							<br />
							<h1
								style={{
									textAlign: "center",
									margin: "4px 0px"
								}}>
								<span
									style={{
										color: theme.palette.primary.main,
										fontWeight: 900
									}}>
									OFFER
								</span>
								<br />
								<span
									style={{
										fontWeight: 900,
										color: theme.palette.secondary.dark
									}}>
									{" "}
									SUBMITTED
								</span>
							</h1>
							<p
								style={{
									margin: "0px",
									padding: "0px 8px",
									textAlign: "center",
									color: "#929292"
								}}>
								{_.startCase(props.storeName)} will accept or
								reject within {props.response} hours
							</p>
							<Lottie
								height={props.isMobile ? 150 : 200}
								width='80%'
								options={{
									loop: false,
									autoplay: true,
									animationData: offerSubmitted.default,
									rendererSettings: {
										preserveAspectRatio: "xMidYMid slice"
									}
								}}
							/>
							<br />
							{data.anonymous ? (
								<div style={{ textAlign: "center" }}>
									<Link
										to={{
											pathname: "/signup",
											search: window.location.search
										}}
										style={{
											textDecoration: "none",
											color: "#000"
										}}>
										<Button
											variant='contained'
											className={classes.bigButton}>
											Sign Up
										</Button>
									</Link>
									<Grid
										container
										style={{
											textAlign: "center",
											fontWeight: "700"
										}}>
										<Grid
											item
											xs={6}
											style={{ paddingLeft: "1.5em" }}>
											<DoneIcon
												className={classes.icon}
											/>{" "}
											Save shipping details
										</Grid>
										<Grid
											item
											xs={6}
											style={{ paddingRight: "1.5em" }}>
											<DoneIcon
												className={classes.icon}
											/>{" "}
											Edit & track offers
										</Grid>
									</Grid>
									<br />
								</div>
							) : (
								<div style={{ textAlign: "center" }}>
									<Button
										variant='contained'
										className={classes.bigButton}
										onClick={() => {
											window.location.href =
												"https://my.enteroffer.com";
										}}>
										Visit Dashboard
									</Button>
									<br />
									<br />
								</div>
							)}
							<Paper
								elevation={0}
								style={{
									padding: "1em 0em",
									textAlign: "center",
									marginBottom: "30px",
									boxShadow:
										"0 1px 6px rgba(0,0,0,0.16), 0 1px 6px rgba(0,0,0,0.23)",
									borderRadius: "2em 2em 0px 0px"
								}}>
								<p
									style={{
										textAlign: "center",
										color: "#929292"
									}}>
									<span
										style={{
											fontWeight: 800,
											color: theme.palette.secondary.dark,
											fontSize: "1.2em"
										}}>
										OFFER RECEIPT
									</span>
									<br />
									emailed to{" "}
									<b>{data.shippingAddress.email}</b>
								</p>
								<Divider />
								<br />
								<ReactCSSTransitionGroup
									transitionName='loadingItem'
									transitionAppear={true}
									transitionAppearTimeout={500}
									transitionEnterTimeout={500}
									transitionLeaveTimeout={300}>
									<img
										alt={`${props.storeName}`}
										src={`https://s3-ap-southeast-2.amazonaws.com/oaklas/logos/${props.storeName}Logo.png`}
										style={{
											maxWidth: "60%",
											maxHeight: " 60px",
											display: "inline-block",
											backgroundColor: "#fff"
										}}
									/>
								</ReactCSSTransitionGroup>
								<br />
								<Divider />
								{data &&
									_.map(data.offer, (product, key) => (
										<Grid
											container
											key={key}
											style={{
												padding: "1em 2em",
												textAlign: "left"
											}}>
											<Grid item container xs={4}>
												<div
													style={{
														height: "150px",
														width: "90%",
														border: "1px solid",
														borderColor:
															theme.palette
																.secondary
																.light,
														backgroundColor: "#fff"
													}}>
													<ReactCSSTransitionGroup
														transitionName='loadingItem'
														transitionAppear={true}
														transitionAppearTimeout={
															500
														}
														transitionEnterTimeout={
															500
														}
														transitionLeaveTimeout={
															300
														}>
														<img
															className={
																classes.img
															}
															src={product.image}
															alt={product.title}
														/>
													</ReactCSSTransitionGroup>
												</div>
											</Grid>
											<Grid item xs={8} container>
												<Grid item xs={12} container>
													<Grid
														item
														xs={12}
														style={{
															paddingBottom:
																"0em",
															paddingRight: "0em"
														}}>
														<Typography
															style={{
																fontSize: props.isMobile
																	? "0.9em"
																	: "1.1em",
																fontWeight:
																	"700",
																overflow:
																	"hidden",
																textOverflow:
																	"ellipsis",
																WebkitLineClamp:
																	"2",
																WebKitBoxOrient:
																	"vertical"
															}}>
															{product.title}
														</Typography>
														<Typography
															style={{
																fontSize:
																	"0.9em",
																fontWeight:
																	"700",
																color:
																	"#929292",
																margin:
																	"-3px auto 5px"
															}}>
															{`${
																data.paymentInfo
																	.currency
															} ${
																data.paymentInfo
																	.currency_symbol
															}${Number(
																product.price
															).toFixed(2)}`}
														</Typography>
														<Grid
															item
															xs={12}
															container>
															<Grid
																item
																xs={
																	props.isMobile
																		? 4
																		: 3
																}
																container>
																<TextField
																	id={`outlined-select-${product.variantID}`}
																	// select
																	disabled
																	className={
																		classes.textFieldDropdown
																	}
																	value={
																		product.quantity
																	}
																	style={{
																		marginRight:
																			"4px",
																		marginLeft:
																			"-14px"
																	}}
																	InputProps={{
																		classes: {
																			input:
																				classes.inputDrop,
																			notchedOutline:
																				classes.notchedOutline
																		},
																		startAdornment: (
																			<InputAdornment position='start'>
																				<span
																					style={{
																						paddingLeft:
																							"-14px",
																						fontSize:
																							"0.8em",
																						color:
																							"#929292",
																						fontWeight:
																							"700"
																					}}>
																					QTY
																				</span>
																			</InputAdornment>
																		)
																	}}
																	variant='outlined'>
																	...
																</TextField>
															</Grid>
															<Grid
																item
																xs={
																	props.isMobile
																		? 8
																		: 9
																}
																container>
																<TextField
																	id={`outlined-variants-${product.variantID}`}
																	// select
																	disabled
																	className={
																		classes.textFieldDropdown
																	}
																	value={
																		product.variantType
																	}
																	style={{
																		width:
																			"100%"
																	}}
																	InputProps={{
																		classes: {
																			input:
																				classes.inputDrop,
																			notchedOutline:
																				classes.notchedOutline
																		},
																		startAdornment: (
																			<InputAdornment position='start'>
																				<span
																					style={{
																						fontSize:
																							"0.8em",
																						color:
																							"#929292",
																						fontWeight:
																							"700"
																					}}>
																					Option
																				</span>
																			</InputAdornment>
																		)
																	}}
																	variant='outlined'>
																	...
																</TextField>
															</Grid>
														</Grid>
														{parsed.carta ? null : (
															<Grid
																item
																xs={12}
																container>
																<Typography
																	style={{
																		fontSize: props.isMobile
																			? "0.9em"
																			: "1.1em",
																		margin:
																			"1em 0em"
																	}}>
																	Your Offer:
																	<br />
																	<span
																		style={{
																			fontWeight:
																				"700"
																		}}>
																		{`${
																			data
																				.paymentInfo
																				.currency
																		} ${
																			data
																				.paymentInfo
																				.currency_symbol
																		}${Number(
																			product.bidPrice
																		).toFixed(
																			2
																		)}`}
																	</span>
																	{product.quantity >
																	1 ? (
																		<span
																			style={{
																				color:
																					"#929292"
																			}}>
																			{
																				" each"
																			}
																		</span>
																	) : null}
																</Typography>
															</Grid>
														)}
													</Grid>
												</Grid>
											</Grid>
										</Grid>
									))}

								<Divider />
								<p
									style={{
										margin: "1em 2em",
										textAlign: "left"
									}}>
									Cart Total:
									<span
										style={{
											fontWeight: 700,
											float: "right"
										}}>
										{`${data.paymentInfo.currency} ${
											data.paymentInfo.currency_symbol
										}${Number(props.cartTotal).toFixed(2)}`}
									</span>
								</p>
								<p
									style={{
										margin: "1em 2em",
										textAlign: "left"
									}}>
									{props.shipping.name}:
									<span
										style={{
											fontWeight: 700,
											float: "right"
										}}>
										{`${data.paymentInfo.currency} ${
											data.paymentInfo.currency_symbol
										}${Number(props.shipping.price).toFixed(
											2
										)}`}
									</span>
								</p>
								<p
									style={{
										margin: "1em 2em",
										textAlign: "left"
									}}>
									Offer Total:
									<span
										style={{
											fontWeight: 700,
											float: "right"
										}}>
										{`${data.paymentInfo.currency} ${
											data.paymentInfo.currency_symbol
										}${Number(data.total).toFixed(2)}`}
									</span>
								</p>
								<Divider />

								<p
									style={{
										color: "#929292",
										margin: "1em 2em 3em",
										textAlign: "left"
									}}>
									If accepted your order will be shipped to:
									<br />
									<br />
									<span
										style={{
											fontWeight: 700,
											color: theme.palette.secondary.dark
										}}>
										{data.shippingAddress.company !== ""
											? data.shippingAddress.company
											: null}
										{data.shippingAddress.company !== "" ? (
											<br />
										) : null}
										{data.shippingAddress.line1}
										<br />
										{data.shippingAddress.city}
										<br />
										{data.shippingAddress.state}{" "}
										{data.shippingAddress.address_zip}
										<br />
										{data.shippingAddress.country}
									</span>
								</p>
							</Paper>
							<div
								style={{
									backgroundColor:
										theme.palette.type === "dark"
											? theme.palette.secondary.main
											: "#3F414C",
									color: "#FFF",
									width: "100vw",
									marginLeft: "calc(-50vw + 50%)"
								}}>
								<Grid
									container
									direction='row'
									xs={props.isMobile ? 12 : 8}
									justify='flex-start'
									alignItems='flex-start'
									style={{ padding: "1em 2em" }}>
									<Grid item xs={props.isMobile ? 6 : 4}>
										<p>
											<b>EnterOffer</b>
										</p>
										<p style={{ lineHeight: "1.8" }}>
											<a
												href='https://enteroffer.com/about/'
												target='_blank'
												rel='noopener noreferrer'
												style={{
													textDecoration: "none",
													color: "#FFF"
												}}>
												About Us
											</a>
											<br />
											<span
												style={{ cursor: "pointer" }}
												onClick={handleClickOpenTerms}>
												Term of Use
											</span>
											<br />
											<span
												style={{ cursor: "pointer" }}
												onClick={
													handleClickOpenPrivacy
												}>
												Privacy Policy
											</span>
											<br />
										</p>
									</Grid>
									<Grid item xs={props.isMobile ? 6 : 4}>
										<p>
											<b>Help & Support</b>
										</p>
										<p style={{ lineHeight: "1.8" }}>
											<a
												href='https://enteroffer.com/faq/'
												target='_blank'
												rel='noopener noreferrer'
												style={{
													textDecoration: "none",
													color: "#FFF"
												}}>
												FAQ
											</a>
											<br />
											<a
												href='https://enteroffer.com/#contactUs'
												target='_blank'
												rel='noopener noreferrer'
												style={{
													textDecoration: "none",
													color: "#FFF"
												}}>
												Contact Us
											</a>
											<br />
										</p>
									</Grid>

									<Grid item xs={props.isMobile ? 6 : 4}>
										<p>
											<b>Follow Us</b>
										</p>
										<p style={{ lineHeight: "1.8" }}>
											<a
												href='https://www.instagram.com/enteroffer/'
												target='_blank'
												rel='noopener noreferrer'
												style={{
													textDecoration: "none",
													color: "#FFF"
												}}>
												Instagram
											</a>
											<br />
											<a
												href='https://www.facebook.com/enteroffer/'
												target='_blank'
												rel='noopener noreferrer'
												style={{
													textDecoration: "none",
													color: "#FFF"
												}}>
												Facebook
											</a>
											<br />
											<a
												href='https://twitter.com/EnterOffer'
												target='_blank'
												rel='noopener noreferrer'
												style={{
													textDecoration: "none",
													color: "#FFF"
												}}>
												Twitter
											</a>
											<br />
										</p>
									</Grid>
								</Grid>
							</div>
							<div
								style={{
									backgroundColor:
										theme.palette.type === "dark"
											? theme.palette.secondary.light
											: "#F6F6F8",
									width: "100vw",
									marginLeft: "calc(-50vw + 50%)",
									padding: "0px -8px -8px 8px"
								}}>
								<Toolbar className={classes.toolbar}>
									<div>
										<a href='https://www.enteroffer.com/'>
											<img
												src={
													theme.palette.type ===
													"dark"
														? "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlueAlt.png"
														: "https://s3-ap-southeast-2.amazonaws.com/oaklas/assets/enterOfferBlue.png"
												}
												alt='enterOffer'
												style={{
													width: props.isMobile
														? "7em"
														: "9em",
													display: "block",
													marginLeft: "auto",
													marginRight: "auto"
												}}
											/>
										</a>
									</div>
									<div>
										<p
											className={classes.button}
											style={{
												fontWeight: 300,
												fontSize: props.isMobile
													? "0.75em"
													: null
											}}>
											Copyright &copy; 2019 EnterOffer
										</p>
									</div>
								</Toolbar>
							</div>
						</Grid>
					</Fade>
				</Grid>
			</div>
		)
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(Submit);
