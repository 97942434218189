import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Slider } from "@material-ui/core";
import _ from "lodash";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import withSizes from "react-sizes";
import { RoomDetails, SubmitOffer } from "./index";

const styles = theme => ({
	root: {
		flexGrow: 1
	},
	paper: {
		padding: theme.spacing(2),
		maxWidth: 500
	},
	button: {
		padding: "0.2em"
	},
	image: {
		height: "130px"
	},
	img: {
		margin: "auto",
		display: "block",
		maxWidth: "100%",
		height: "100%",
		objectFit: "cover"
	},
	container: {
		display: "flex",
		flexWrap: "wrap"
	}
});

const ValueSlider = withStyles({
	root: {
		// color: "#52af77",
		// background: "linear-gradient(to right, #ff0000 0%, #00ff00 100%)",
		height: 8
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "1px solid",
		borderColor: "#f6f6f8",
		boxShadow: "#ccc 0px 2px 3px 1px",
		marginTop: -8,
		marginLeft: -12,
		"&:focus,&:hover,&$active": {
			boxShadow: "#ccc 0px 2px 5px 1px"
		}
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 4px)"
	},
	track: {
		color: "transparent",
		height: 10,
		borderRadius: 6
	},
	mark: {
		color: "transparent"
	},
	markLabel: {
		color: "#7f7f7f",
		fontSize: "0.8em"
	},
	rail: {
		opacity: 1,
		background:
			"linear-gradient(to right, #ff0000 , #ff8800, #ffff00, #00ff00 )",
		height: 10,
		borderRadius: 6
	}
})(Slider);
class OfferSelected extends React.Component {
	state = {
		open: false,
		product: [],
		value: 0,
		total: 0,
		nights: 0
	};

	componentDidMount() {
		if (this.props.data) {
			this.setState({
				data: this.props.data,
				product: this.props.product,
				prevProduct: this.props.product,
				value: Math.round(
					Number(
						this.props.product.price -
							this.props.data.existingBooking.price
					) * 0.6
				),
				nights: this.props.data.nights,
				total:
					Math.round(
						Number(
							this.props.product.price -
								this.props.data.existingBooking.price
						) * 0.6
					) * this.props.data.nights
			});
		}
	}
	componentDidUpdate() {
		if (!_.isEqual(this.state.prevProduct, this.props.product)) {
			this.setState({
				product: this.props.product,
				prevProduct: this.props.product,
				value: Math.round(
					Number(
						this.props.product.price -
							this.props.data.existingBooking.price
					) * 0.6
				),
				nights: this.props.data.nights,
				total:
					Math.round(
						Number(
							this.props.product.price -
								this.props.data.existingBooking.price
						) * 0.6
					) * this.props.data.nights
			});
		}
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};
	handleChangeSlider = (event, value) => {
		this.setState({ value: value, total: value * this.state.nights });
	};

	handleClickOpen = () => {
		this.setState({ open: true });
	};

	handleClose = () => {
		this.setState({ open: false });
	};

	handleSubmit = obj => {
		console.log("submitting!", obj);
	};

	render() {
		const { classes } = this.props;
		const { data, product, value, total } = this.state;

		return (
			<div>
				{product && data ? (
					<Grid container spacing={2} style={{ marginBottom: "1em" }}>
						<Grid item xs={4}>
							<Grid container spacing={0}>
								<Grid item xs={12} className={classes.image}>
									<ReactCSSTransitionGroup
										transitionName='loadingItem'
										transitionAppear={true}
										transitionAppearTimeout={500}
										transitionEnterTimeout={500}
										transitionLeaveTimeout={300}>
										<img
											className={classes.img}
											alt={product.title}
											src={product.image}
										/>
									</ReactCSSTransitionGroup>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={8} container>
							<Grid item xs={12} container>
								<Grid
									item
									xs={12}
									style={{
										paddingBottom: "0em",
										paddingRight: "0em"
									}}>
									<Typography
										style={{
											fontSize: this.props.isMobile
												? "0.9em"
												: "1em",
											fontWeight: "700",
											overflow: "hidden",
											textOverflow: "ellipsis",
											WebkitLineClamp: "2",
											WebKitBoxOrient: "vertical"
										}}>
										{product.title}
									</Typography>
									<RoomDetails details={product.roomInfo} />
									<Typography
										style={{
											fontSize: "0.8em",
											fontWeight: "700",
											color: "#929292",
											margin: "8px auto 0px"
										}}>
										Dates
									</Typography>
									<Typography
										style={{
											fontSize: "0.9em",
											fontWeight: "700",
											margin: "-3px auto 12px"
										}}>
										{`${data.startDate} - ${data.endDate}`}
									</Typography>
									<Typography
										style={{
											fontSize: "0.8em",
											fontWeight: "700",
											color: "#929292",
											margin: "-3px auto 0px"
										}}>
										Rooms & Guests
									</Typography>
									<Typography
										style={{
											fontSize: "0.9em",
											fontWeight: "700",
											margin: "-3px auto 12px"
										}}>
										{`${data.rooms} Room : ${data.adult} Adult`}
										{data.child === 0
											? null
											: `${data.child} Child`}
									</Typography>
									<Typography
										style={{
											fontSize: "0.8em",
											fontWeight: "700",
											color: "#929292",
											margin: "-3px auto 0px"
										}}>
										Your Upgrade Offer
									</Typography>
									<Typography
										style={{
											fontSize: "1.3em",
											fontWeight: "900",
											margin: "-3px auto 12px"
										}}>
										{`${data.currency} ${data.currencySymbol}${value} `}
										<span
											style={{
												fontSize: "0.6em",
												fontWeight: 700
											}}>
											Per Night
										</span>
									</Typography>
									<Typography
										style={{
											fontSize: "0.8em",
											fontWeight: 700,
											margin: "-12px auto 12px"
										}}>
										{`${data.currency} ${
											data.currencySymbol
										}${value * data.nights} `}
										<span style={{ fontWeight: 700 }}>
											Total per room
										</span>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<ValueSlider
							style={{ margin: "12px" }}
							aria-label='value-slider'
							min={0}
							max={product.price - data.existingBooking.price}
							value={value}
							onChange={this.handleChangeSlider}
						/>
						<p
							style={{
								margin: "-12px 12px 4px",
								width: "100%",
								fontSize: "0.8em"
							}}>
							<span
								style={{
									color: "#7f7f7f",
									display: "inline",
									float: "left"
								}}>
								{data
									? `${data.currency} ${data.currencySymbol}0`
									: null}
							</span>
							<span
								style={{
									display: "inline",
									marginLeft: "0.5em",
									color: "#7f7f7f",
									float: "right"
								}}>
								{data && product
									? `Total upgrade value ${data.currency} ${
											data.currencySymbol
									  }${product.price -
											data.existingBooking.price}`
									: null}
							</span>
						</p>
					</Grid>
				) : null}
				<SubmitOffer
					data={data}
					product={product}
					total={total}
					submitOffer={this.handleSubmit}
					isMobile={this.props.isMobile}
					// response={values.data.response}
					// currency={values.data.currency}
					// currencySymb={values.data.currency_symbol}
					// selectedShipping={values.selectedShipping}
					// submitOffer={submitOffer}
					// submitDialogue={values.submitDialogue}
					offerErrorOpen={false}
					// submitting={submitting}
				/>
			</div>
		);
	}
}
OfferSelected.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(withStyles(styles)(OfferSelected));
