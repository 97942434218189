import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import RightArrow from "@material-ui/icons/KeyboardArrowRight";
import { Button, Grid, IconButton, Divider } from "@material-ui/core";
import withSizes from "react-sizes";
import { authFunctions, db, cf } from "../firebase";
import _ from "lodash";
import Lottie from "react-lottie";
import * as loader from "../assets/loader.json";

import { Header } from "../components";

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: 500,
		margin: "1em auto"
	},
	textColor: {
		color: theme.palette.secondary.dark
	},
	textColorInfo: {
		color: "#7f7f7f"
	}
}));

function signOut() {
	authFunctions.doSignOut();
}

function Settings(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [values, setValues] = useState({
		meta: {},
		payment: {},
		cardString: "",
		loading: true
	});

	useEffect(() => {
		var meta = {};
		db.getMeta()
			.then(snapshot => {
				if (snapshot !== null && snapshot !== undefined) {
					meta = snapshot;
				}
				return cf.getPaymentDetails();
			})
			.then(snapshot => {
				if (snapshot !== null && snapshot !== undefined) {
					var cardString = `${snapshot.type}\u00A0\u00A0\u00A0\u00A0 •••• ${snapshot.last4}`;
					let month = snapshot.month;
					if (month < 10) {
						cardString =
							cardString +
							`\u00A0\u00A0\u00A0\u00A0 0${month}/${snapshot.year}`;
					} else {
						cardString =
							cardString +
							`\u00A0\u00A0\u00A0\u00A0 ${month}/${snapshot.year}`;
					}
				}
				setValues({
					...values,
					payment: snapshot,
					meta: meta,
					cardString: cardString,
					loading: false
				});
			})
			.catch(console.error);
	}, []);

	const darkModeCallback = e => {
		props.darkModeCallback(e);
	};

	return (
		(window.location.hash = "Settings"),
		(
			<div style={{ fontFamily: props.font }}>
				<Header
					username={`${values.meta.firstName} ${values.meta.lastName}`}
					size={true}
					darkModeCallback={darkModeCallback}
				/>
				<div
					className={classes.root}
					style={{
						padding: props.isMobile ? "1em 2em" : null,
						marginTop: props.isMobile ? "4em" : "6em",
						fontFamily: props.font
					}}>
					<h2>
						<span
							style={{
								color: theme.palette.primary.main,
								fontWeight: 900
							}}>
							ACCOUNT
						</span>
						<span
							style={{
								fontWeight: 900,
								color: theme.palette.secondary.dark
							}}>
							{" "}
							SETTINGS
						</span>
					</h2>
					<br />
					{values.loading ? (
						<Lottie
							height={200}
							width={200}
							options={{
								loop: true,
								autoplay: true,
								animationData: loader.default,
								rendererSettings: {
									preserveAspectRatio: "xMidYMid slice"
								}
							}}
						/>
					) : (
						<div>
							<p className={classes.textColor}>
								Name
								<br />
								<span
									style={{ fontWeight: 700 }}
									className={classes.textColorInfo}>
									{values.meta.firstName}{" "}
									{values.meta.lastName}
								</span>
							</p>
							<p className={classes.textColor}>
								Email
								<br />
								<span
									style={{ fontWeight: 700 }}
									className={classes.textColorInfo}>
									{values.meta.email}
								</span>
							</p>
							<Grid container spacing={1}>
								<Grid item xs={6}>
									<p className={classes.textColor}>
										Shipping Address
										<br />
										{!_.isEmpty(
											values.meta.shippingAddress
										) ? (
											<span
												style={{ fontWeight: 700 }}
												className={
													classes.textColorInfo
												}>
												{
													values.meta.shippingAddress
														.line1
												}
												<br />
												{
													values.meta.shippingAddress
														.city
												}
												<br />
												{
													values.meta.shippingAddress
														.state_code
												}{" "}
												{
													values.meta.shippingAddress
														.address_zip
												}
												<br />
												{
													values.meta.shippingAddress
														.country
												}
											</span>
										) : null}
									</p>
								</Grid>
								<Grid item xs={6}>
									<p className={classes.textColor}>
										Billing Address
										<br />
										{!_.isEmpty(
											values.meta.billingAddress
										) ? (
											<span
												style={{ fontWeight: 700 }}
												className={
													classes.textColorInfo
												}>
												{
													values.meta.billingAddress
														.line1
												}
												<br />
												{
													values.meta.billingAddress
														.city
												}
												<br />
												{
													values.meta.billingAddress
														.state_code
												}{" "}
												{
													values.meta.billingAddress
														.address_zip
												}
												<br />
												{
													values.meta.billingAddress
														.country
												}
											</span>
										) : null}
									</p>
								</Grid>
							</Grid>
							<p className={classes.textColor}>
								Payment
								<br />
								<span
									style={{ fontWeight: 700 }}
									className={classes.textColorInfo}>
									{values.cardString}
								</span>
							</p>
						</div>
					)}
					<Divider />
					<a
						href='https://my.enteroffer.com'
						target='_blank'
						rel='noopener noreferrer'
						style={{
							textDecoration: "none",
							color: theme.palette.secondary.dark,
							fontWeight: 700
						}}>
						<p style={{ margin: "1.5em 0em" }}>
							{" "}
							Visit your Dashboard
							<IconButton
								className={classes.button}
								style={{ float: "right", margin: "-0.5em 0em" }}
								aria-label='Edit'>
								<RightArrow />
							</IconButton>
						</p>
					</a>
					<Divider />
					<Button
						onClick={() => signOut()}
						style={{
							fontWeight: 700,
							fontSize: "1em",
							margin: "0.5em 0em",
							textTransform: "capitalize"
						}}>
						Sign Out
					</Button>
				</div>
			</div>
		)
	);
}

const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withSizes(mapSizesToProps)(Settings);
