import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles, useTheme } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Divider from "@material-ui/core/Divider";
import Lottie from "react-lottie";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as errorAnimation from "../assets/errorAnimation.json";
import _ from "lodash";
import withSizes from "react-sizes";
import { db } from "../firebase";

const styles = theme => ({
	submitOfferButton: {
		color:
			theme.palette.primary.main === "#35B2FF" ||
			theme.palette.primary.main === "#35b2ff"
				? "#FFF"
				: theme.palette.primary.contrastText,
		background: theme.palette.primary.main,
		border: "none",
		width: "100%",
		fontSize: "1.3em",
		fontWeight: "600",
		letterSpacing: "1px",
		display: "inline-block",
		margin: "0em auto 0.5em auto",
		"&:hover": {
			backgroundColor: theme.palette.primary.dark
		}
	},
	button: {
		margin: theme.spacing(1),
		background: "#f6f6f8",
		color: theme.palette.primary.main,
		fontWeight: 600,
		boxShadow: "none",
		"&:hover": {
			backgroundColor: "#D3D3D3"
		}
	},
	circularProgress: {
		color: "#ffffff",
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12
	},

	cartTotal: {
		display: "block",
		fontWeight: "700",
		width: "100%",
		fontSize: "1.2em",
		borderRadius: "2px",
		textAlign: "center"
	}
});

function SubmitOffer(props) {
	const { classes, data, product, total } = props;
	const theme = useTheme();

	const [values, setValues] = useState({
		anonymous: true
	});

	var discount = Number(props.couponValue);
	discount = -1 * discount;

	useEffect(() => {
		let anon = false;
		db.getMeta().then(snapshot => {
			if (!_.isEmpty(snapshot)) {
				if (snapshot.anonymous === true) {
					anon = true;
				}
			}
			setValues({ ...values, anonymous: anon });
		});
	}, []);

	var obj = {
		test: "information"
	};

	return (
		<div>
			<Dialog
				open={props.offerErrorOpen}
				onClose={props.handleOfferErrorClose}>
				<DialogTitle
					style={{
						textAlign: "center"
					}}>
					{"Offer Submission Error"}
				</DialogTitle>
				<DialogContent>
					<Lottie
						options={{
							loop: true,
							autoplay: true,
							animationData: errorAnimation.default,
							rendererSettings: {
								preserveAspectRatio: "xMidYMid slice"
							}
						}}
					/>
				</DialogContent>
				<DialogContentText
					variant='subtitle1'
					align='center'
					style={{ textAlign: "center" }}>
					{`Your offer could not be submitted.`}
				</DialogContentText>
				<DialogContentText
					variant='subtitle1'
					align='center'
					style={{ textAlign: "center" }}>
					{`Please try again.`}
				</DialogContentText>
				<DialogActions style={{ textAlign: "center" }}>
					<Button
						onClick={props.handleOfferErrorClose}
						className={classes.button}
						style={{ display: "inline-block" }}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
			<Divider />
			<Typography
				style={{
					fontSize: props.isMobile ? "0.9em" : "1em",
					fontWeight: "700",
					margin: "12px 0px"
				}}>
				Your existing booking
			</Typography>
			<div
				style={{
					lineHeight: "1em",
					backgroundColor: theme.palette.secondary.main,
					fontWeight: "600",
					fontSize: "0.8em",
					color: "#7f7f7f"
				}}>
				<Grid container spacing={1}>
					<Grid item xs={5} style={{ fontWeight: 700 }}>
						Customer Name:
					</Grid>
					<Grid
						item
						xs={7}
						style={{
							fontWeight: 700,
							color: theme.palette.secondary.dark
						}}>
						{data ? `${data.firstName} ${data.lastName}` : null}
					</Grid>
					<br />
					<br />
					<Grid item xs={5} style={{ fontWeight: 700 }}>
						Customer Email:
					</Grid>
					<Grid
						item
						xs={7}
						style={{
							fontWeight: 700,
							color: theme.palette.secondary.dark
						}}>
						{data ? `${data.email}` : null}
					</Grid>
					<br />
					<br />
					<Grid item xs={5} style={{ fontWeight: 700 }}>
						Itenerary Number:
					</Grid>
					<Grid
						item
						xs={7}
						style={{
							fontWeight: 700,
							color: theme.palette.secondary.dark
						}}>
						{data ? `${data.iterNo}` : null}
					</Grid>
					<br />
					<br />
					<Grid item xs={5} style={{ fontWeight: 700 }}>
						Room Type:
					</Grid>
					<Grid
						item
						xs={7}
						style={{
							fontWeight: 700,
							color: theme.palette.secondary.dark,
							lineHeight: 1.2
						}}>
						{data ? `${data.existingBooking.title}` : null}
					</Grid>
					<br />
					<br />
				</Grid>
				<br />
				<div
					style={{
						border: "1px solid #92929288",
						borderRadius: "5px",
						// fontSize: "0.8em",
						lineHeight: 1.3,
						padding: "12px 18px",
						textAlign: "center"
					}}>
					{
						"Upgrade offer acceptance is at the complete discretion of  "
					}
					<b>{data ? data.hotelName : null}</b>
					{
						" and subject to availability. If accepted your initial reservation plus upgrade offer total is payable at check-in. Our standard cancellation policy applies."
					}
				</div>
				<br />
				<br />
				<div className={classes.cartTotal}>
					<p
						style={{
							margin: "0px"
						}}>
						<span
							style={{
								color: theme.palette.secondary.dark,
								display: "inline",
								float: "left"
							}}>
							Offer Total
						</span>
						<span
							style={{
								display: "inline",
								marginLeft: "0.5em",
								color: theme.palette.primary.main,
								float: "right"
							}}>
							{data
								? `${data.currency} ${data.currencySymbol}${total}`
								: null}
						</span>
					</p>
					<br />
					<br />
				</div>
				<Button
					variant='contained'
					disabled={props.submitting || props.demo}
					className={classes.submitOfferButton}
					onClick={() => props.submitOffer(obj)}>
					{!props.submitting ? (
						"Submit Upgrade Offer"
					) : (
						<CircularProgress
							size={24}
							className={classes.circularProgress}
						/>
					)}
				</Button>
			</div>
			<div
				style={{
					padding: "0em 1.250em",
					backgroundColor: theme.palette.secondary.main,
					fontWeight: "800",
					textAlign: "center",
					color: "#7f7f7f"
				}}>
				<p
					style={{
						textAlign: "center",
						fontSize: "0.8em",
						fontWeight: "600"
					}}>
					{"By clicking 'Submit Upgrade Offer' you agree to the "}
					<a
						style={{
							color: theme.palette.primary.main,
							textDecoration: "none"
						}}
						href='https://www.enteroffer.com/terms/'
						target='_blank'
						rel='noopener noreferrer'>
						Terms & Conditions
					</a>
					{" and "}
					<a
						style={{
							color: theme.palette.primary.main,
							textDecoration: "none"
						}}
						href='https://www.enteroffer.com/privacy/'
						target='_blank'
						rel='noopener noreferrer'>
						Privacy Policy
					</a>
					{"."}
				</p>
			</div>
		</div>
	);
}

SubmitOffer.propTypes = {
	classes: PropTypes.object.isRequired
};
const mapSizesToProps = ({ width }) => ({
	isMobile: width < 600
});

export default withStyles(styles)(withSizes(mapSizesToProps)(SubmitOffer));
